import React from 'react'
import Table from 'react-bootstrap/Table';


const PriceList = ({ adblue, efecta_95, efecta_diesel, verva_100, verva_diesel, h2, updated, discount }) => {

    const formatDate = (dateString) => {
        const options = { year: "numeric", month: "long", day: "numeric", hour: "numeric", minute: "numeric" }
        return new Date(dateString).toLocaleDateString(undefined, options)
    }

    return (
        <div style={{
            marginTop: 50
        }}>
            <h2>Ceník</h2>
            <p>Aktualizováno {formatDate(updated)}</p>
            <Table striped bordered hover>
                <thead>
                    <tr>
                        <th>Produkt</th>
                        <th>Vyhlášená cena</th>
                        <th>Cena po slevě</th>
                    </tr>
                </thead>
                <tbody>
                    <tr>
                        <td>AdBlue</td>
                        <td>{adblue} Kč</td>
                        <td>{(adblue - discount).toFixed(2)} Kč</td>
                    </tr>
                    <tr>
                        <td>Efecta 95</td>
                        <td>{efecta_95} Kč</td>
                        <td>{(efecta_95 - discount).toFixed(2)} Kč</td>
                    </tr>
                    <tr>
                        <td>Efecta Diesel</td>
                        <td>{efecta_diesel} Kč</td>
                        <td>{(efecta_diesel - discount).toFixed(2)} Kč</td>
                    </tr>
                    <tr>
                        <td>Verva 100</td>
                        <td>{verva_100} Kč</td>
                        <td>{(verva_100 - discount).toFixed(2)} Kč</td>
                    </tr>
                    <tr>
                        <td>Verva Diesel</td>
                        <td>{verva_diesel} Kč</td>
                        <td>{(verva_diesel - discount).toFixed(2)} Kč</td>
                    </tr>
                    <tr>
                        <td>h2</td>
                        <td>{h2} Kč</td>
                        <td>{(h2 - discount).toFixed(2)} Kč</td>
                    </tr>
                </tbody>
            </Table>
        </div>
    )
}

export default PriceList