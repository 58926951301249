import { db } from './firebase'
import { useState, useEffect } from 'react'
import { collection, getDocs } from 'firebase/firestore';
import './App.css';
import PriceList from './components/PriceList/PriceList';
import PriceCalculator from './components/PriceCalculator/PriceCalculator';

function App() {
  const discount = 2.2;
  const [prices, setPrices] = useState({});
  const fetchPost = async () => {

    const querySnapshot = await getDocs(collection(db, "benzina"))
    const newData = await querySnapshot.docs
      .map((doc) => ({ ...doc.data(), id: doc.id, discount }));
    setPrices(newData[0]);
    console.log(newData[0]);

  }
  useEffect(() => {
    fetchPost();
  }, [])

  return (
    <div className="App">
      <PriceCalculator {...prices}></PriceCalculator>
      <PriceList {...prices}></PriceList>
    </div>
  );
}

export default App;
