import React from 'react'
import { useState } from 'react'
import Form from 'react-bootstrap/Form';


const PriceCalculator = ({ adblue, efecta_95, efecta_diesel, verva_100, verva_diesel, h2, discount }) => {
    const [liters, setLiters] = useState();
    const [result, setResult] = useState(0.00);
    const [selectValue, setSelectValue] = useState(efecta_95 ?? null);

    const calculateResult = (price, amount) => {
        const calculation = (price - discount) * amount
        const result = calculation > 0 ? calculation : 0;
        setResult(result.toFixed(2));
    }

    const onChange = (e) => {
        setSelectValue(e.target.value);
        calculateResult(e.target.value, liters);
    }

    const onInput = (e) => {
        setLiters(e.target.value);
        calculateResult(selectValue ? selectValue : efecta_95, e.target.value);
    }

    return (
        <div>
            <h2>Kalkulačka</h2>
            <h4>{result} Kč</h4>
            <Form.Select onChange={onChange}>
                <option value={efecta_95} selected>Efecta 95</option>
                <option value={verva_100}>Verva 100</option>
                <option value={efecta_diesel}>Efecta diesel</option>
                <option value={verva_diesel}>Verva diesel</option>
                <option value={adblue}>AdBlue</option>
                <option value={adblue}>H2</option>ß
            </Form.Select>
            <Form.Control type="number" value={liters} onInput={onInput} placeholder="Počet litrů" />

        </div >
    )
}

export default PriceCalculator