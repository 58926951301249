// Import the functions you need from the SDKs you need
import { initializeApp } from "firebase/app";
import { getFirestore } from "firebase/firestore";
// TODO: Add SDKs for Firebase products that you want to use
// https://firebase.google.com/docs/web/setup#available-libraries

// Your web app's Firebase configuration
const firebaseConfig = {
    apiKey: "AIzaSyAvfb-l36g-YxxA-Ab6PVB5mDZNotLoH6I",
    authDomain: "benzina-a584c.firebaseapp.com",
    projectId: "benzina-a584c",
    storageBucket: "benzina-a584c.appspot.com",
    messagingSenderId: "581786050958",
    appId: "1:581786050958:web:beb96b1a3b90eb8e2204df"
};

// Initialize Firebase
const app = initializeApp(firebaseConfig);
// Export firestore database
// It will be imported into your react app whenever it is needed
export const db = getFirestore(app);